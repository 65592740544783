<template>
  <main v-if="pageContent != null">
    <div :class="'pointer-all card-wrapper flex transparent-' + this.$store.state.alreadyVisitedConfig ">
      <div class="width-6 width-11-m content flex flex-align-center">
        <div class="card-no-style card-large">
          <!-- <button class="cta-button" @click="NavigateToLastPage">close</button> -->
          <h1>{{pageContent.Title}}</h1>
          <VueMarkdown      
          class="article-content-inner"
          :source="pageContent.Description"></VueMarkdown>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import IOMixins from "../Mixins/IOMixins";
import VueMarkdown from '@/Components/vue-markdown.vue';

export default {
  name : "FallbackPage",
  components:{VueMarkdown},
  mixins: [IOMixins],
  data(){
    return {
      pageContent : null,
    }
  },
  mounted(){
  
    this.Get("/ui-section-fallback").then((response)=>{
      this.pageContent = response.data;
    });
  },
}
</script>
<style lang="scss">

.UI.Fallback{
  background: #eeeeee;
  color:#fff;
  
  .card-large{
    margin: auto;
  }
}

.fallback{
  display:flex;
  justify-content: center;
  align-content: center;

  
}
</style>