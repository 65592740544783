<template>
  <div class="wrapper">
    <transition name="fade">
      <div v-if="loading" class="loading">
        <div class="loading-inner"></div>
      </div>
    </transition>
    <transition name="fade">
      <div class="kontakt" v-if="content != null">
        <div class="kontakt-header">
          <img
            :src="config.CMS_BASE_URL + content.Image.url"
            :alt="content.Image.alternativeText"
          />
        </div>
        <h1>{{ content.Title }}</h1>
        <VueMarkdown :source="content.Description"></VueMarkdown>
      </div>
    </transition>

    <div class="navigation">
      <button class="back-home" @click="NavigateToLastPage">Zurück</button>
    </div>
    <div id="gallery"></div>
  </div>
</template>

<script>
import Gallery360 from "../Scene/Gallery360";
import config from "../../main.config";
import axios from "axios";
import mainConfig from "../../main.config";
import VueMarkdown from '@/Components/vue-markdown.vue'

export default {
  components: {},
  name: "PageGallery",
  components:{
    VueMarkdown
  },
  mounted() {
    this.init();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  data() {
    return {
      loading: true,
      config: config,
      from: null,
      gallery: null,
      content: null,
    };
  },
  beforeDestroy() {
    this.gallery.Destroy();
  },
  methods: {
    NavigateToLastPage() {
      var potentials = [
        "Home",
        "Landing",
        "Explorer",
        "ExplorerSingle",
        "Configurator",
        "Dashboard",
      ];
      if (this.from != null && potentials.includes(this.from.name)) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    init() {
      if (this.gallery == null) {
        this.gallery = new Gallery360({
          domElement: "gallery",
          canvasElement: "canvas-holder",
          loaded: () => {
            this.loading = false;
          },
        });
      }

      axios
        .get(mainConfig.CMS_BASE_URL + "/ui-section-contact")
        .then((response) => {
          this.content = response.data;
        })
        .catch((error) => {});

      this.gallery.Show();
    },
  },
};
</script>

<style lang="scss">
.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #efefef;
}
.back-home {
  position: absolute;
  color: $orange;
  top: 3rem;
  right: 3rem;
  z-index: 9999;
  background: #fff;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}
#gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  //cursor: move;
}

.kontakt {
  position: absolute;
  z-index: 9999;
  width: 40%;
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
  right: 1rem;
  top: 110px;
  max-height: calc(100vh - 210px);
  overflow: auto;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar,
  &::-moz-scrollbar {
    display: none;
  }
}
p {
  margin-bottom: 1rem;
}
.kontakt-header {
  margin-bottom: 2rem;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
}

.kontakt-header {
  margin: -2rem;
  margin-bottom: 2rem;
}
</style>
